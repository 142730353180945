import React, { useState } from 'react';
import './Contact.css';

const Alert = ({ message, onClose, type }) => (
    <div className={`alert alert-${type}`}>
        <span className="alert-message">{message}</span>
        <button className="alert-close" onClick={onClose}>×</button>
    </div>
);

const ContactForm = () => {
    const [formData, setFormData] = useState({
        First_Name: '',
        Last_Name: '',
        Email: '',
        Message: '',
    });
    const [file, setFile] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('success');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleInputFocus = (e) => {
        e.target.parentNode.classList.add("focus", "not-empty");
    };

    const handleInputBlur = (e) => {
        if (e.target.value === "") {
            e.target.parentNode.classList.remove("not-empty");
        }
        e.target.parentNode.classList.remove("focus");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();

        for (const key in formData) {
            formDataToSend.append(key, formData[key]);
        }
        if (file) {
            formDataToSend.append('file', file);
        }

        try {
            const response = await fetch('https://contact.fairpay.earth/send-email', {
                method: 'POST',
                body: formDataToSend,
            });

            if (response.ok) {
                setAlertType('success');
                setAlertMessage('Successfully sent!');
                setShowAlert(true);
                // Reset form
                setFormData({
                    First_Name: '',
                    Last_Name: '',
                    Email: '',
                    Message: '',
                });
                setFile(null);
            } else {
                throw new Error('Failed to send message');
            }
        } catch (error) {
            console.error('Error:', error);
            setAlertType('error');
            setAlertMessage('Failed to send message. Please try again.');
            setShowAlert(true);
        }
    };

    return (
        <>
            {showAlert && (
                <Alert
                    message={alertMessage}
                    onClose={() => setShowAlert(false)}
                    type={alertType}
                />
            )}
            <form onSubmit={handleSubmit} className="contact-form">
                <div className="input-wrap">
                    <input
                        type="text"
                        name="First_Name"
                        value={formData.First_Name}
                        onChange={handleChange}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        className="contact-input"
                        required
                    />
                    <label>First Name</label>
                    <i className="icon fa-solid fa-id-card"></i>
                </div>
                <div className="input-wrap">
                    <input
                        type="text"
                        name="Last_Name"
                        value={formData.Last_Name}
                        onChange={handleChange}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        className="contact-input"
                        required
                    />
                    <label>Last Name</label>
                    <i className="icon fa-solid fa-id-card"></i>
                </div>
                <div className="input-wrap w-100">
                    <input
                        type="email"
                        name="Email"
                        value={formData.Email}
                        onChange={handleChange}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        className="contact-input"
                        required
                    />
                    <label>Email</label>
                    <i className="icon fa-solid fa-envelope"></i>
                </div>
                <div className="input-wrap text-area w-100">
                    <textarea
                        name="Message"
                        value={formData.Message}
                        onChange={handleChange}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                        className="contact-input"
                        required
                    ></textarea>
                    <label>Message</label>
                    <i className="icon fa-solid fa-inbox"></i>
                </div>
                <div className="contact-buttons w-100">
                    <button className="btn upload">
                        <span>
                            <i className="fa-solid fa-paperclip"></i>Add attachment
                        </span>
                        <input type="file" name="file" onChange={handleFileChange} />
                    </button>
                    <button type="submit" className="btn">Send message</button>
                </div>
            </form>
        </>
    );
};

export default ContactForm;