import './Style.css'
import React, { useState, useEffect, useRef } from 'react';
import contactImage from "../Components/Assets/contactimage.jpg"
import Contact from "./Contact";

const Page = () => {
    const [activeSection, setActiveSection] = useState('home');
    const [menuActive, setMenuActive] = useState(false);
    const headerRef = useRef(null);
    const sectionsRef = useRef([]);

    useEffect(() => {
        const headerHeight = headerRef.current.offsetHeight;

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setActiveSection(entry.target.id);
                }
            });
        }, {
            rootMargin: `-${headerHeight}px 0px -50% 0px`,
            threshold: 0.1
        });

        sectionsRef.current.forEach(section => section && observer.observe(section));
        return () => {
            sectionsRef.current.forEach(section => section && observer.unobserve(section));
        };
    }, []);

    const handleNavClick = (e, sectionId) => {
        e.preventDefault();
        const section = document.getElementById(sectionId);
        const headerHeight = headerRef.current.offsetHeight;
        window.scrollTo({
            top: section.offsetTop - headerHeight + 5,
            behavior: 'smooth'
        });
        setMenuActive(false);
    };

    return (
        <>
            <header ref={headerRef}>
                <a href="/" className="logo">Fairpay.<span className="animate nav" style={{"--i": 1}}></span></a>
                <nav className={`navbar ${menuActive ? 'active' : ''}`}>
                    {['home', 'services', 'impact', 'contact'].map((section) => (
                        <a
                            href={`#${section}`}
                            key={section}
                            className={activeSection === section ? 'active' : ''}
                            onClick={(e) => handleNavClick(e, section)}
                        >
                            {section.charAt(0).toUpperCase() + section.slice(1)}
                        </a>
                    ))}
                    <span className="animate nav" style={{"--i": 2}}></span>
                </nav>
                <div
                    className={`hamburger-menu ${menuActive ? 'active' : ''}`}
                    onClick={() => setMenuActive(!menuActive)}
                >
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </header>

            <section id="home" className="home" ref={el => sectionsRef.current[0] = el}>
                <span className="animate bg"></span>
                <div className="home-content">
                    <h1>The One-Click<span className="animate" style={{"--i": 3}}></span></h1>
                    <h1>Sustainability Solution<span className="animate" style={{"--i": 3}}></span></h1>
                    <h1>for your Webshop<span className="animate" style={{"--i": 3}}></span></h1>
                    <p>Our integrated carbon offsetting service
                        helps customers and webshops neutralize their environmental
                        impact of their purchases,
                        all with just a click. <span className="animate" style={{"--i": 3}}></span></p>

                    <div className="btn-box">
                        <a
                            href="#contact"
                            className="btn"
                            onClick={(e) => handleNavClick(e, 'contact')}
                        >
                            Get started
                        </a>
                        <a
                            href="#services"
                            className="btn"
                            onClick={(e) => handleNavClick(e, 'services')}
                        >
                            Learn more
                        </a>
                        <span className="animate" style={{"--i": 3}}></span>
                    </div>
                </div>
            </section>

            <section className="services" id="services" ref={el => sectionsRef.current[1] = el}>
                <div className="container">
                    <div className="above">
                        <div className="services-heading">
                            <h1>Make environmental change in just<span> one click</span></h1>
                            <p>We enable webshops to grow responsibly, contributing to a greener, more sustainable
                                future for e-commerce.</p>
                        </div>
                    </div>

                    <div className="product-overview">
                        <div className="left">
                            <div className="performance-content">
                                {[
                                    { percentage: '92%', content: "of customers want to support brands taking positive climate action" },
                                    { percentage: '28%', content: "of shoppers choose to make their orders carbon neutral" },
                                    { percentage: '14%', content: "average cart conversion boost by adding Fairpay to your webshop" },
                                    { percentage: '100%', content: "Easy integration and reliable customer support" }
                                ].map((item, index) => (
                                    <div className="block" key={index}>
                                        <div className="percentage">{item.percentage}</div>
                                        <div className="content">{item.content}</div>
                                        <div className="progressbar">
                                            <div className="progress">
                                                <div role="progressbar" className="progress-bar" style={{width: '0%'}} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="right">
                            {/* You can add a chart or graph here using recharts if needed */}
                        </div>
                    </div>
                </div>
            </section>

            <section id="impact" ref={el => sectionsRef.current[2] = el}>
                <div className="impact-container">
                    <h2>Our Impact</h2>
                    <div className="impact-grid">
                        {[
                            {
                                svgPath: "M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3",
                                title: "1,000,000+",
                                description: "Carbon offsets purchased"
                            },
                            {
                                svgPath: "M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
                                title: "50+",
                                description: "Countries reached"
                            },
                            {
                                svgPath: "M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9",
                                title: "100,000+",
                                description: "Trees planted"
                            }
                        ].map((item, index) => (
                            <div className="impact-card" key={index}>
                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path d={item.svgPath} />
                                </svg>
                                <h3>{item.title}</h3>
                                <p>{item.description}</p>
                            </div>
                        ))}
                    </div>
                    <a href="/impact" className="learn-more">Learn More About Our Impact</a>
                </div>
            </section>

            <section className="contact" id="contact" ref={el => sectionsRef.current[3] = el}>
                <div className="container">
                    <div className="left">
                        <div className="form-wrapper">
                            <div className="contact-heading">
                                <h1>Let's work together.</h1>
                                <p className="contact-text">Or reach us via : <a
                                    href="mailto:steven@fairpay.earth">steven@fairpay.earth</a></p>
                            </div>

                        <Contact></Contact>
                        </div>
                    </div>
                    <div className="right">
                        <div className="image-wrapper">
                            <img src={contactImage} alt="Contact" className="img"/>
                            <div className="wave-wrap">
                                <svg className="wave" viewBox="0 0 783 1536" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path id="wave"
                                          d="M236.705 1356.18C200.542 1483.72 64.5004 1528.54 1 1535V1H770.538C793.858 63.1213 797.23 196.197 624.165 231.531C407.833 275.698 274.374 331.715 450.884 568.709C627.393 805.704 510.079 815.399 347.561 939.282C185.043 1063.17 281.908 1196.74 236.705 1356.18Z"/>
                                </svg>
                            </div>
                            <svg className="dashed-wave" viewBox="0 0 345 877" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path id="dashed-wave"
                                      d="M0.5 876C25.6667 836.167 73.2 739.8 62 673C48 589.5 35.5 499.5 125.5 462C215.5 424.5 150 365 87 333.5C24 302 44 237.5 125.5 213.5C207 189.5 307 138.5 246 87C185 35.5 297 1 344.5 1"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </section>

            <footer className="footer-class">
            <div className="footer-content">
                    <h1>Fairpay.</h1>
                    <p>Make environmental change in just one click</p>
                    <div className="icons">
                        {['facebook', 'twitter', 'instagram', 'linkedin'].map((platform, index) => (
                            <a href={`https://www.${platform}.com/your-profile`} key={index}><i className={`fab fa-${platform}`}></i></a>
                        ))}
                    </div>
                </div>

                <div className="footer-content links">
                    <h4>Quick links</h4>
                    {['home', 'services', 'impact', 'contact'].map((link, index) => (
                        <li key={index}><a href={`#${link}`}>{link.charAt(0).toUpperCase() + link.slice(1)}</a></li>
                    ))}
                </div>

                <div className="footer-content information">
                    <h4>Contact us</h4>
                    <li><a href="mailto:steven@fairpay.earth">steven@fairpay.earth</a></li>
                    <li><a href="tel:+31637175117">+31 6 37 17 51 17</a></li>
                </div>
            </footer>
        </>
    );
};

export default Page;